<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <supply tempType="01" titleName="我的供应商"></supply>
  </div>
</template>
<script>
  import supply from '@/views/common/supply.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  export default {
    name: 'mysupplyList',
    components: {
      supply,
      breadcrumb
    },
    data() {
      return {
        breadStaticData:['承诺达标合格证管理', '我的供应商'],
        linkData:[],
        thirdStaticData:[],
        contHeight: ''
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
</style>